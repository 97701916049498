import React from "react";
import PropTypes from "prop-types";

/**
 * InputGroup
 */
const InputGroup = props => {
  return (
    <fieldset>
      <label className="block text-md4 md:text-md3 text-accent1 mb-8" htmlFor={props.name}>
        {props.label}
      </label>

      <input
        css={{ lineHeight: "normal" }}
        className="text-md4 md:text-md3 text-text1 w-full h-56 md:h-64 px-12 md:px-16 rounded-md"
        onChange={props.onChange}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
      />
    </fieldset>
  );
};

InputGroup.propTypes = {
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
InputGroup.defaultProps = {
  type: "text",
};

export default InputGroup;
