import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import axios from "axios";

import theme from "../../constants/theme";

import { Wrapper, Button, RichDescription } from "../core";
import { InputGroup, TextAreaGroup } from "../form";
import { illustrationBlankBlogPreview } from "../../assets/graphics";

import getFormEndPoint from "../../constants/getFormEndPoint";

/**
 * ContactFormSection
 */
const ContactFormSection = props => {
  const [isFormValid, setFormValid] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [serverStatus, setServerStatus] = useState({
    isSubmitting: false,
    success: null,
  });

  useEffect(() => {
    checkFormValidation();
  }, [formData]);

  function resetForm() {
    setFormValid(false);
    setFormData({
      name: "",
      email: "",
      message: "",
    });
    setServerStatus({
      isSubmitting: false,
      success: null,
    });
  }

  function checkFormValidation() {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    emailRegex.test(formData.email) &&
    formData.name !== "" &&
    formData.email !== "" &&
    formData.message !== ""
      ? setFormValid(true)
      : setFormValid(false);
  }

  function handleServerResponse(success, form) {
    setServerStatus({
      isSubmitting: false,
      success,
    });
    if (success) {
      form.reset();
    }
  }

  function updateForm(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function submitForm(e) {
    e.preventDefault();

    let form = e.target;
    setServerStatus({ isSubmitting: true });

    axios({
      method: "post",
      url: getFormEndPoint,
      data: new FormData(form),
    })
      .then(_ => {
        handleServerResponse(true, form);
      })
      .catch(_ => {
        handleServerResponse(false, form);
      });
  }

  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {leadText()}

        <div className={theme.spacing.spacerH_titleContent} />

        {formGroup()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div className="w-auto md:w-6/12 m-auto">
        <h2 className="text-md md:text-lg3 text-text1 text-center leading-normal">{props.title}</h2>

        <div className="h-10 md:h-20 bg-transparent" />

        <RichDescription
          className={`text-center`}
          blocksObject={props.descriptionRichText}
          type={`sectionDescription`}
        />
        {/* <p className="text-md4 md:text-md3 text-text2 text-center leading-loose">
          {props.description}
        </p> */}
      </div>
    );
  }

  function formGroup() {
    return (
      <div className="flex flex-col w-auto md:w-10/12 lg:w-8/12 m-auto p-30 md:p-40 bg-bg2 rounded-lg">
        {serverStatus.success ? confirmation() : form()}
      </div>
    );
  }

  function form() {
    let formFields = props.formFields ?? [];
    return (
      <form onSubmit={submitForm}>
        <div className="grid grid-cols-1 gap-20 md:gap-30">
          {formFields.map(field => {
            return field.element === "input" ? (
              <InputGroup
                onChange={updateForm}
                value={formData[field.name]}
                key={field.name}
                type={field.type}
                name={field.name}
                label={field.label.localized}
                placeholder={field.placeholder.localized}
              />
            ) : field.element === "textarea" ? (
              <TextAreaGroup
                onChange={updateForm}
                value={formData[field.name]}
                key={field.name}
                name={field.name}
                label={field.label.localized}
                placeholder={field.placeholder.localized}
              />
            ) : null;
          })}
          <button
            type="submit"
            disabled={!isFormValid || serverStatus.isSubmitting}
            css={{
              backgroundColor: theme.colors.accent2,
              "&:hover": {
                backgroundColor:
                  !isFormValid || serverStatus.isSubmitting
                    ? theme.colors.accent2
                    : theme.colors.accent2_hover,
              },
            }}
            className={cx(
              !isFormValid ? "opacity-50" : "opacity-100",
              "inline-block outline-none w-full h-56 md:h-64 rounded-md"
            )}>
            <span className="text-md4 text-altText1">
              {serverStatus.isSubmitting ? "One moment please" : props.submitButtonTitle}
            </span>
          </button>
        </div>
      </form>
    );
  }

  function confirmation() {
    return (
      <div className="flex flex-col py-40 md:py-80">
        <img
          className="w-full h-auto max-h-240 object-contain object-center"
          src={illustrationBlankBlogPreview}
          alt={props.confirmation.title}
        />
        <h3 className="text-md2 text-text1 leading-none text-center w-auto md:w-8/12 lg:w-6/12 m-auto">
          {props.confirmation.title}
        </h3>
        <div className="h-20 bg-transparent" />
        <p className="text-md3 text-text3 text-center leading-relaxed w-auto md:w-8/12 lg:w-6/12 m-auto">
          {props.confirmation.description}
        </p>
        <div className="h-40 bg-transparent" />
        <div className="m-auto">
          <Button
            onClick={resetForm}
            mode="button"
            label={props.confirmation.buttonTitle}
            frameColor={theme.colors.accent1}
            frameColorHover={theme.colors.accent1_hover}
            size={`lg`}
            isStretch={false}
          />
        </div>
      </div>
    );
  }
};

import { mockContactFormConfirmation } from "../../constants/mockData";

ContactFormSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  formFields: PropTypes.array,
  submitButtonTitle: PropTypes.string,
  confirmation: PropTypes.object,
  backgroundColor: PropTypes.string,
};
ContactFormSection.defaultProps = {
  descriptionRichText: {},
  formFields: [],
  confirmation: mockContactFormConfirmation,
  backgroundColor: theme.colorsTw.bg_bg1,
};

export default ContactFormSection;
