import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { LocaleLink } from "../core";
import { illustrationBlankBlogPreview } from "../../assets/graphics";

/**
 * BlogPreviewCard
 */
const BlogPreviewCard = props => {
  return (
    <LocaleLink to={props.to}>
      <div className="bg-bg1 flex flex-col rounded-md shadow transition-all duration-300 hover:shadow-lg">
        {imagePane()}
        {textPane()}
      </div>
    </LocaleLink>
  );

  // Image --------------------
  function imagePane() {
    return <div className="px-20 md:px-30 pt-20 md:pt-30">{coverImage()}</div>;
  }

  function coverImage() {
    return (
      <div className="">
        <img
          className="w-full h-320 md:h-240 object-contain object-center"
          src={!props.coverImageUrl ? illustrationBlankBlogPreview : props.coverImageUrl}
          alt={props.title}
        />
      </div>
    );
  }

  // Text --------------------
  function textPane() {
    return (
      <div className="w-full px-20 md:px-30 pt-16 pb-20 md:pb-30 md:min-h-200">
        <p className="text-sm3 text-accent2 uppercase tracking-wide_lg">{props.category}</p>
        <div className="h-4 bg-transparent" />
        <h3 className="text-md3 lg:text-md2 text-text1 leading-none">{props.title}</h3>
        <div className="h-4 bg-transparent" />

        <div className="flex">
          <p className="text-sm text-accent1 leading-loose">
            <span className="text-text2">By</span> {props.author}
          </p>
          <div className="w-12 bg-transparent" />
          <p className="text-sm text-text2 leading-loose">{props.date}</p>
        </div>
      </div>
    );
  }
};

BlogPreviewCard.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  coverImageUrl: PropTypes.string,
};
BlogPreviewCard.defaultProps = {};

export default BlogPreviewCard;
