import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, LocaleButton, SolutionsTabNav, RichDescription } from "../core";
import { SolutionCard } from "../blocks";

/**
 * SolutionsPreviewSection
 */
const SolutionsPreviewSection = props => {
  const [activeSolution, setActiveSolution] = useState(0);

  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        <div className="flex flex-col md:flex-row justify-between">
          {leadText()}
          <div className="h-20 md:hidden bg-transparent" />
          {tabs()}
        </div>

        <div className={theme.spacing.spacerH_titleContent} />

        {solutions()}

        <div className={theme.spacing.spacerH_titleContent} />

        {nextAction()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div className="w-auto md:w-6/12 lg:w-5/12 pr-20">
        <p className="text-md4 text-accent2 uppercase tracking-wide_lg">
          {props.solutions[activeSolution].solutionPreview?.subTitle?.localized}
        </p>
        <h2 className="text-md md:text-lg3 text-text1 leading-normal">
          {props.solutions[activeSolution].solutionPreview?.solutionTitle}
        </h2>

        <div className="h-10 md:h-20 bg-transparent" />

        <RichDescription
          blocksObject={props.solutions[activeSolution].solutionPreview?.descriptionRichText ?? {}}
        />
        {/* <p className="text-md4 md:text-md3 text-text2 leading-loose">
          {props.solutions[activeSolution].solutionPreview?.description?.localized}
        </p> */}
      </div>
    );
  }

  function onTabClick(index) {
    setActiveSolution(index);
  }

  function tabs() {
    return (
      <div className="w-auto md:w-480">
        <div className="flex justify-end items-end h-full">
          <SolutionsTabNav
            onClick={onTabClick}
            activeTab={activeSolution}
            tabItems={props.tabItems ?? []}
          />
        </div>
      </div>
    );
  }

  function solutions() {
    return (
      <div className="w-auto md:w-12/12">
        <div className="grid grid-cols-1 gap-20 md:gap-30 lg:gap-40">
          {props.solutions[activeSolution].solutionPreview?.products?.map(
            ({ productPreview }, index) => {
              return index % 2 === 0 ? (
                <SolutionCard
                  key={productPreview.productTitle}
                  title={productPreview.productTitle}
                  subTitle={productPreview.subTitle?.localized}
                  description={productPreview.description?.localized}
                  descriptionRichText={productPreview.descriptionRichText ?? {}}
                  buttonTitle={productPreview.callToAction?.linkTitle?.localized}
                  buttonCallToAction={productPreview.callToAction?.route?.slug?.current}
                  appIconUrl={productPreview.iconImage?.asset?.fluid?.src}
                  coverImageUrl={productPreview.mainImage?.asset?.fluid?.src}
                />
              ) : (
                <SolutionCard
                  key={productPreview.productTitle}
                  title={productPreview.productTitle}
                  subTitle={productPreview.subTitle?.localized}
                  description={productPreview.description?.localized}
                  descriptionRichText={productPreview.descriptionRichText ?? {}}
                  buttonTitle={productPreview.callToAction?.linkTitle?.localized}
                  buttonCallToAction={productPreview.callToAction?.route?.slug?.current}
                  appIconUrl={productPreview.iconImage?.asset?.fluid?.src}
                  coverImageUrl={productPreview.mainImage?.asset?.fluid?.src}
                  isFlipped={true}
                />
              );
            }
          )}
        </div>
      </div>
    );
  }

  function nextAction() {
    return (
      <div className="w-auto md:w-5/12 lg:w-3/12 px-none md:px-20 lg:px-none m-auto">
        <LocaleButton
          to={
            props.solutions[activeSolution].solutionPreview?.detailPageCallToAction?.route?.slug
              ?.current
          }
          label={
            props.solutions[activeSolution].solutionPreview?.detailPageCallToAction?.linkTitle
              ?.localized
          }
          size={"lg"}
          labelColor={theme.colors.accent1}
          frameColor={theme.colors.accent1_dimmed}
          frameColorHover={theme.colors.accent1_dimmed_hover}
        />
      </div>
    );
  }
};

SolutionsPreviewSection.propTypes = {
  solutions: PropTypes.array.isRequired,
  tabItems: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string,
};
SolutionsPreviewSection.defaultProps = {
  solutions: [],
  tabItems: [],
  backgroundColor: theme.colorsTw.bg_bg2,
};

export default SolutionsPreviewSection;
