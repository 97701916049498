import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, CoverImage, RichDescription } from "../core";

/**
 * StandOutSection
 */
const StandOutSection = props => {
  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>{feature()}</Wrapper>
    </section>
  );

  function feature() {
    return (
      <div className="flex flex-col md:flex-row items-center">
        <div className={cx(props.isFlipped ? "block" : "hidden", "lg:w-1/12")}></div>

        <div className={cx(props.isFlipped ? "md:order-last" : "order-first", "w-auto md:w-6/12")}>
          <CoverImage imageUrl={props.coverImageUrl} alt={props.title} />
        </div>

        <div className="h-40 md:hidden bg-transparent" />

        <div className="w-auto md:w-6/12 lg:w-5/12">
          <div className="px-20">
            <p className="text-sm text-accent2 uppercase tracking-wide_lg">{props.subTitle}</p>
            <div className="h-4 bg-transparent" />
            <h2 className="text-md lg:text-lg4 text-text1 leading-none">{props.title}</h2>
            <div className="h-20 lg:h-30 bg-transparent" />
            <RichDescription blocksObject={props.descriptionRichText} />
            {/* <p className="text-sm md:text-md4 text-text2 leading-loose">{props.description}</p> */}
          </div>
        </div>
      </div>
    );
  }
};

StandOutSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  coverImageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
  isFlipped: PropTypes.bool,
};
StandOutSection.defaultProps = {
  descriptionRichText: {},
  backgroundColor: theme.colorsTw.bg_bg1,
  isFlipped: false,
};

export default StandOutSection;
