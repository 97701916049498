import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "../core";

/**
 * ProductDetailHeader
 */
const ProductDetailHeader = props => {
  return (
    <header className={cx(props.backgroundColor, theme.spacing.sectionY_md)}>
      <Wrapper isFullHeight={true}>{feature()}</Wrapper>
    </header>
  );

  function feature() {
    return (
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="w-auto md:w-6/12 px-20 md:pl-none md:pr-40">
          <img
            className="w-full max-h-500 object-contain"
            src={props.coverImageUrl}
            alt={props.title}
          />
        </div>

        <div className="h-40 md:hidden bg-transparent" />

        <div className="w-full md:w-4/12">
          <div className="px-20 md:pl-20">
            <p className="font-medium text-md4 text-altText1_60 uppercase tracking-wide_lg">
              {props.subTitle}
            </p>
            <div className="h-4 bg-transparent" />
            <h2 className="text-lg3 md:text-lg2 text-altText1 leading-none">{props.title}</h2>

            <div className="h-10 md:h-20 bg-transparent" />

            <p className="text-md3 md:text-md2 text-text1 leading-relaxed">{props.description}</p>
          </div>
        </div>
      </div>
    );
  }
};

ProductDetailHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  coverImageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
};
ProductDetailHeader.defaultProps = {
  backgroundColor: theme.colorsTw.bg_accent2,
};

export default ProductDetailHeader;
