import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, CoverImage, IconFrame, RichDescription } from "../core";

/**
 * ListCheckBulletsSection
 */
const ListCheckBulletsSection = props => {
  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {leadText()}

        <div className={theme.spacing.spacerH_titleContent} />

        {feature()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div className="w-auto md:w-full">
        <p className="text-md4 text-accent2 uppercase tracking-wide_lg">{props.subTitle}</p>
        <h2 className="text-md md:text-lg4 text-text1 text-center leading-normal">{props.title}</h2>

        <div className="h-10 md:h-20 bg-transparent" />

        <RichDescription blocksObject={props.descriptionRichText} />
        {/* <p className="text-md4 md:text-md3 text-text2 leading-loose">{props.description}</p> */}
      </div>
    );
  }

  function feature() {
    return (
      <div className="flex flex-col md:flex-row">
        <div className="w-auto md:w-6/12">
          <CoverImage imageUrl={props.coverImageUrl} alt={props.title} />
        </div>

        <div className="h-30 md:hidden bg-transparent" />

        <div className="w-auto md:w-4/12">{props.bulletItems.length > 0 ? bulletList() : null}</div>
      </div>
    );
  }

  function bulletList() {
    return (
      <ul className="grid grid-cols-1 gap-30">
        {props.bulletItems.map(item => {
          return listItem(
            item.iconImage?.asset?.fluid?.src,
            item.title?.localized,
            item.subTitle?.localized
          );
        })}
      </ul>
    );
  }

  function listItem(iconUrl, title = "", description = "") {
    return (
      <li key={title} className="flex justify-start items-center px-20 md:px-0">
        <IconFrame iconSize={24} framePadding={12} iconUrl={iconUrl} />
        <div className="px-10 md:pt-8">
          <h3 className="text-md3 md:text-md2 text-accent1 leading-none">{title}</h3>
          <div className="h-4 md:h-12 bg-transparent" />
          <p className="text-sm md:text-md4 text-text2 leading-relaxed">{description}</p>
        </div>
      </li>
    );
  }
};

ListCheckBulletsSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  coverImageUrl: PropTypes.string.isRequired,
  bulletItems: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string,
  isFlipped: PropTypes.bool,
};
ListCheckBulletsSection.defaultProps = {
  descriptionRichText: {},
  bulletItems: [],
  backgroundColor: theme.colorsTw.bg_bg1,
  isFlipped: false,
};

export default ListCheckBulletsSection;
