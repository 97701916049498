import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, LocaleButton } from "../core";
import { dotsBlocksTopLeft, dotsBlocksBottomRight } from "../../assets/graphics";

/**
 * CTASection
 */
const CTASection = props => {
  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY_md)}>{card()}</section>
  );

  function card() {
    return (
      <Wrapper>
        <div
          css={{
            backgroundImage: `url(${dotsBlocksTopLeft}), url(${dotsBlocksBottomRight})`,
            backgroundPosition: "top left, bottom right",
            backgroundRepeat: "no-repeat",
            backgroundSize: 100,
            [theme.mq(theme.screens.md)]: {
              backgroundSize: 150,
            },
            [theme.mq(theme.screens.lg)]: {
              backgroundSize: 200,
            },
          }}
          className={cx(
            props.cardBackgroundColor,
            "rounded",
            "flex flex-col justify-center items-center",
            "px-30 py-40 md:py-60 lg:py-80"
          )}>
          <div className="w-auto md:w-8/12 lg:w-6/12">
            <h2 className="text-md md:text-lg3 text-altText1 text-center leading-none">
              {props.title}
            </h2>
            <div className="h-10 bg-transparent" />
            <p className="text-md3 md:text-md2 text-altText2 text-center leading-relaxed">
              {props.description}
            </p>
          </div>

          <div className="h-30 bg-transparent" />

          <div className="w-full md:w-4/12 lg:w-3/12">
            <LocaleButton
              to={props.callToActionRoute}
              label={props.callToActionTitle}
              labelColor={theme.colors.text1}
              frameColor={theme.colors.bg1}
              frameColorHover={theme.colors.bg1_hover}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
};

CTASection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  callToActionTitle: PropTypes.string,
  callToActionRoute: PropTypes.string,
  backgroundColor: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
};
CTASection.defaultProps = {
  backgroundColor: theme.colorsTw.bg_bg1,
  cardBackgroundColor: theme.colorsTw.bg_accent1,
};

export default CTASection;
