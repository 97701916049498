import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "../core";

/**
 * SolutionDetailHeader
 */
const SolutionDetailHeader = props => {
  return (
    <header className={cx(props.backgroundColor, theme.spacing.sectionY_md)}>
      <Wrapper isFullHeight={true}>
        <div className="flex flex-col items-center">
          {appIcon()}
          <div className="h-20 md:h-30 bg-transparent" />
          {textGroup()}
        </div>
      </Wrapper>
    </header>
  );

  function appIcon() {
    return (
      <img
        className="w-70 md:w-80 h-70 md:h-80 object-cover rounded-bubble"
        src={props.appIconUrl}
        alt={props.title}
      />
    );
  }

  function textGroup() {
    return (
      <div className="flex flex-col items-center w-auto md:w-8/12 lg:w-6/12">
        <h1 className="text-lg4 md:text-lg3 text-altText1 text-center leading-none">
          {props.title}
        </h1>
        <div className="h-10 bg-transparent" />
        <p className="text-md3 md:text-md2 text-text1 text-center leading-relaxed md:leading-loose px-40 md:px-none">
          {props.subTitle}
        </p>
      </div>
    );
  }
};

SolutionDetailHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  appIconUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
};
SolutionDetailHeader.defaultProps = {
  backgroundColor: theme.colorsTw.bg_accent2,
};

export default SolutionDetailHeader;
