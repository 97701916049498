import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { LocaleButton, RichDescription } from "../core";

/**
 * SolutionCard
 */
const SolutionCard = props => {
  return (
    <div className="bg-bg1 flex flex-col md:flex-row rounded shadow transition-all duration-300 hover:shadow-lg">
      {imagePane()}
      {textPane()}
    </div>
  );

  // Image --------------------
  function imagePane() {
    let flipped = props.isFlipped ? "md:order-last" : "md:order-first";
    return (
      <div className={cx(flipped, "md:pt-50")}>
        <div className="relative">
          {appIcon()}
          {coverImage()}
        </div>
      </div>
    );
  }

  function appIcon() {
    let iconPosition = props.isFlipped
      ? "bottom-0 right-10 md:bottom-auto md:left-0"
      : "bottom-0 right-10 md:bottom-auto md:right-0";
    return (
      <div className={cx("absolute", iconPosition, "bg-bg1 w-80 h-80 rounded-bubble")}>
        <img
          className="rounded-bubble border border-gray-300"
          src={props.appIconUrl}
          alt={props.title}
        />
      </div>
    );
  }

  function coverImage() {
    let imageSizePadding = props.isFlipped ? "md:pl-10" : "md:pr-10";
    return (
      <div className={cx("pb-20 md:pb-none md:pt-10", imageSizePadding)}>
        <div className="bg-accent2_dimmed w-full lg:w-520 h-300 lg:h-440 rounded">
          <img
            className="w-full h-full object-contain object-bottom rounded"
            src={props.coverImageUrl}
            alt={props.title}
          />
        </div>
      </div>
    );
  }

  // Text --------------------
  function textPane() {
    return (
      <div className="flex flex-col justify-between md:items-end p-30 md:px-60 md:pt-60 md:pb-40">
        {textGroup()}

        <div className="h-20 bg-transparent" />

        <div className="w-auto md:w-240">
          <LocaleButton
            to={props.buttonCallToAction}
            label={props.buttonTitle}
            frameColor={theme.colors.accent1}
            frameColorHover={theme.colors.accent1_hover}
          />
        </div>
      </div>
    );
  }

  function textGroup() {
    return (
      <div>
        <p className="text-sm text-accent2 uppercase tracking-wide_lg">{props.subTitle}</p>
        <h3 className="text-lg4 text-text1 leading-normal">{props.title}</h3>
        <div className="h-10 md:h-20 bg-transparent" />
        <RichDescription blocksObject={props.descriptionRichText} />
        {/* <p className="text-md4 text-text2 leading-loose">{props.description}</p> */}
      </div>
    );
  }
};

SolutionCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  buttonTitle: PropTypes.string,
  buttonCallToAction: PropTypes.string,
  appIconUrl: PropTypes.string,
  coverImageUrl: PropTypes.string,
  isFlipped: PropTypes.bool,
};
SolutionCard.defaultProps = {
  descriptionRichText: {},
  isFlipped: false,
};

export default SolutionCard;
