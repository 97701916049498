import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { LocaleButton } from "../core";

/**
 * HeroHeader
 */
const HeroHeader = props => {
  return (
    <header className="relative h-screen-exclNavBar-sm md:h-screen-exclNavBar-md lg:h-screen-exclNavBar-">
      <div className={cx("wrapper", "px-0 md:px-40 lg:px-80")}>{leadText()}</div>
      {coverImage()}
    </header>
  );

  function leadText() {
    return (
      <div className="absolute w-full md:w-auto h-full">
        <div className="flex items-end md:items-center w-full h-full px-30 md:px-0 py-30">
          <div className="md:max-w-480">
            <h2 className="text-lg3 md:text-lg2 lg:text-lg text-altText1 leading-none">
              {props.title}
            </h2>
            <div className="h-20 bg-transparent" />
            <p className="text-md3 md:text-md2 text-altText2 leading-loose">{props.description}</p>

            <div className={theme.spacing.spacerH_titleContent} />

            <div className="w-auto md:w-400 pb-120 md:pb-0">
              <LocaleButton
                to={props.callToActionRoute}
                label={props.callToActionTitle}
                size={"xl"}
                roundness={"round"}
                labelColor={theme.colors.altText1}
                frameColor={theme.colors.accent2}
                frameColorHover={theme.colors.accent2_hover}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  function coverImage() {
    return (
      <div className="w-full h-full">
        <img className="w-full h-full object-cover" src={props.coverImageUrl} alt={props.title} />
      </div>
    );
  }
};

HeroHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  coverImageUrl: PropTypes.string,
  callToActionTitle: PropTypes.string,
  callToActionRoute: PropTypes.string,
};
HeroHeader.defaultProps = {};

export default HeroHeader;
