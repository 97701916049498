import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, SolutionsTabNav, RichDescription } from "../core";
import { SolutionCard } from "../blocks";

/**
 * ProductsPreviewSection
 */
const ProductsPreviewSection = props => {
  const [activeProduct, setActiveProduct] = useState(0);

  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {/* {leadText()}

        <div className={theme.spacing.spacerH_titleContent} /> */}

        {products()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div className="w-auto md:w-6/12 lg:w-5/12">
        <p className="text-md4 text-accent2 uppercase tracking-wide_lg">{props.subTitle}</p>
        <h2 className="text-md md:text-lg3 text-text1 leading-normal">{props.title}</h2>

        <div className="h-10 md:h-20 bg-transparent" />

        <RichDescription blocksObject={props.descriptionRichText} />
        {/* <p className="text-md4 md:text-md3 text-text2 leading-loose">{props.description}</p> */}
      </div>
    );
  }

  function onTabClick(index) {
    setActiveProduct(index);
  }

  function products() {
    let productPreview = props.solutions[activeProduct].productPreview;
    let tabItems = props.solutions.map(solution => {
      return {
        title: solution.productPreview?.productTitle,
        iconUrl: solution.productPreview?.iconImage?.asset?.fluid?.src,
      };
    });

    return (
      <div className="flex flex-col md:flex-row">
        <div className="w-auto md:w-10/12">
          <SolutionCard
            title={productPreview.productTitle}
            subTitle={productPreview.subTitle?.localized}
            description={productPreview.description?.localized}
            descriptionRichText={productPreview.descriptionRichText ?? {}}
            buttonTitle={productPreview.callToAction?.linkTitle?.localized}
            buttonCallToAction={productPreview.callToAction?.route?.slug?.current}
            appIconUrl={productPreview.iconImage?.asset?.fluid?.src}
            coverImageUrl={productPreview.mainImage?.asset?.fluid?.src}
          />
        </div>

        <div className="w-10 bg-transparent" />

        <div className="w-auto md:w-2/12 order-first md:order-last mb-20 md:mb-none">
          <SolutionsTabNav
            onClick={onTabClick}
            activeTab={activeProduct}
            tabItems={tabItems}
            tabDirection={"col"}
          />
        </div>
      </div>
    );
  }
};

ProductsPreviewSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  solutions: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string,
};
ProductsPreviewSection.defaultProps = {
  descriptionRichText: {},
  solutions: [],
  backgroundColor: theme.colorsTw.bg_bg2,
};

export default ProductsPreviewSection;
