import React from "react";
import PropTypes from "prop-types";

/**
 * TextAreaGroup
 */
const TextAreaGroup = props => {
  return (
    <fieldset>
      <label className="block text-md4 md:text-md3 text-accent1 mb-8" htmlFor={props.name}>
        {props.label}
      </label>

      <textarea
        css={{ lineHeight: "normal" }}
        className="text-md4 md:text-md3 text-text1 w-full h-200 md:h-300 px-12 md:px-16 py-12 md:py-16 rounded-md"
        onChange={props.onChange}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
      />
    </fieldset>
  );
};

TextAreaGroup.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
};
TextAreaGroup.defaultProps = {
  rows: 5,
};

export default TextAreaGroup;
