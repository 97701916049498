import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, IconFrame, RichDescription } from "../core";

/**
 * GridIconBulletsSection
 */
const GridIconBulletsSection = props => {
  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {leadText()}

        <div className={theme.spacing.spacerH_titleContent} />

        {bulletList()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div>
        <div className="w-auto md:w-8/12 m-auto">
          <p className="text-sm text-accent2 uppercase md:text-center tracking-wide_lg">
            {props.subTitle}
          </p>
          <div className="h-4 bg-transparent" />
          <h2 className="text-md md:text-lg3 text-text1 md:text-center leading-none">
            {props.title}
          </h2>
        </div>

        <div className="h-10 md:h-20 bg-transparent" />

        <div className="w-auto md:w-6/12 lg:w-5/12 m-auto">
          <RichDescription
            blocksObject={props.descriptionRichText}
            type={`sectionDescription`}
            theme={`dark`}
            color={`text-text1`}
            className={`md:text-center leading-relaxed md:leading-loose`}
          />
          {/* <p className="text-md4 md:text-md3 text-text2 md:text-center leading-relaxed md:leading-loose">
            {props.description}
          </p> */}
        </div>
      </div>
    );
  }

  function bulletList() {
    return (
      <div className="w-full md:w-10/12 lg:w-8/12 md:px-40 m-auto">
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-30 md:gap-40">
          {props.bulletItems.map(item => {
            return listItem(
              item.iconImage?.asset?.fluid?.src,
              item.title?.localized,
              item.subTitle?.localized
            );
          })}
        </ul>
      </div>
    );
  }

  function listItem(iconUrl, title, description) {
    return (
      <li key={title} className="flex md:flex-col items-start">
        <IconFrame framePadding={24} iconUrl={iconUrl} />
        <div className="px-10 md:px-none md:py-20">
          <h3 className="text-md3 md:text-md2 text-accent1 leading-none">{title}</h3>
          <div className="h-4 md:h-12 bg-transparent" />
          <p className="text-sm md:text-md4 text-text2 leading-relaxed">{description}</p>
        </div>
      </li>
    );
  }
};

GridIconBulletsSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  bulletItems: PropTypes.array,
  backgroundColor: PropTypes.string,
};
GridIconBulletsSection.defaultProps = {
  descriptionRichText: {},
  bulletItems: [],
  backgroundColor: theme.colorsTw.bg_bg1,
};

export default GridIconBulletsSection;
