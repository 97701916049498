import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, IconFrame, RichDescription } from "../core";

/**
 * CardBulletsSection
 */
const CardBulletsSection = props => {
  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {leadText()}

        <div className={theme.spacing.spacerH_titleContent} />

        {bulletList()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div>
        <div className="w-auto md:w-8/12 m-auto">
          <p className="text-sm text-altText1_60 uppercase tracking-wide_lg">{props.subTitle}</p>
          <h2 className="text-md md:text-lg3 text-altText1 md:text-center leading-none">
            {props.title}
          </h2>
        </div>

        <div className="h-10 md:h-20 bg-transparent" />

        <div className="w-auto md:w-6/12 lg:w-5/12 m-auto">
          <RichDescription
            blocksObject={props.descriptionRichText}
            type={`sectionDescription`}
            theme={`dark`}
            color={`text-text1`}
            className={`md:text-center leading-relaxed md:leading-loose`}
          />
          {/* <p className="text-md4 md:text-md3 text-text1 md:text-center leading-relaxed md:leading-loose">
            {props.description}
          </p> */}
        </div>
      </div>
    );
  }

  function bulletList() {
    return (
      <div className="w-full md:w-12/12 lg:w-9/12 md:px-40 m-auto">
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-20 md:gap-30 lg:gap-40">
          {props.bulletItems.map(item => {
            return listItem(
              item.iconImage?.asset?.fluid?.src,
              item.title?.localized,
              item.subTitle?.localized
            );
          })}
        </ul>
      </div>
    );
  }

  function listItem(iconUrl, title, description) {
    return (
      <li
        key={title}
        className="flex md:flex-col justify-between items-start h-auto md:h-320 bg-bg1 rounded-md">
        <div className="pt-20 pl-20">
          <IconFrame framePadding={16} iconUrl={iconUrl} />
        </div>
        <div className="p-20 md:p-30 lg:p-40">
          <h3 className="text-md3 md:text-md2 text-accent1 leading-none">{title}</h3>
          <div className="h-4 md:h-12 bg-transparent" />
          <p className="text-sm md:text-md4 text-text2 leading-relaxed">{description}</p>
        </div>
      </li>
    );
  }
};

CardBulletsSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  bulletItems: PropTypes.array,
  backgroundColor: PropTypes.string,
};
CardBulletsSection.defaultProps = {
  descriptionRichText: {},
  bulletItems: [],
  backgroundColor: theme.colorsTw.bg_accent2,
};

export default CardBulletsSection;
