import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper } from "../core";
import {
  tileGeometricCornerTopLeft,
  tileGeometricCornerTopRight,
  tileGeometricCornerBottomLeft,
  tileGeometricCornerBottomRight,
} from "../../assets/graphics";

/**
 * SubHeader
 */
const SubHeader = props => {
  return (
    <header
      css={{
        backgroundImage: `url(${tileGeometricCornerTopLeft}), url(${tileGeometricCornerTopRight}), url(${tileGeometricCornerBottomLeft}), url(${tileGeometricCornerBottomRight})`,
        backgroundPosition: "top left, top right, bottom left, bottom right",
        backgroundRepeat: "no-repeat",
        backgroundSize: 120,
        [theme.mq(theme.screens.md)]: {
          backgroundSize: 200,
        },
        [theme.mq(theme.screens.lg)]: {
          backgroundSize: 280,
        },
      }}
      className={cx("bg-altBg1", theme.spacing.sectionY)}>
      <Wrapper isFullHeight={true}>{textGroup()}</Wrapper>
    </header>
  );

  function textGroup() {
    return (
      <div className="flex items-center w-10/12 md:w-8/12 lg:w-7/12 h-full">
        <div className="">
          <h1 className="text-lg3 md:text-lg2 lg:text-lg text-altText1 leading-none">
            {props.title}
          </h1>
          <div className="h-20 lg:h-30 bg-transparent" />
          <p className="text-md3 md:text-md2 text-altText2 leading-relaxed md:leading-loose">
            {props.subTitle}
          </p>
        </div>
      </div>
    );
  }
};

SubHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};
SubHeader.defaultProps = {};

export default SubHeader;
