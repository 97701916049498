import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import theme from "../../constants/theme";

import { Wrapper, RichDescription } from "../core";

/**
 * CenterImageSection
 */
const CenterImageSection = props => {
  return (
    <section className={cx(props.backgroundColor, theme.spacing.sectionY)}>
      <Wrapper>
        {leadText()}
        <div className="h-60 bg-transparent" />
        {image()}
      </Wrapper>
    </section>
  );

  function leadText() {
    return (
      <div className="w-auto md:w-6/12 lg:w-5/12">
        <p className="text-md4 text-accent2 uppercase tracking-wide_lg">{props.subTitle}</p>
        <h2 className="text-lg4 text-text1 leading-normal">{props.title}</h2>
        <div className="h-20 bg-transparent" />
        <RichDescription blocksObject={props.descriptionRichText} />
        {/* <p className="text-md3 text-text2 leading-loose">{props.description}</p> */}
      </div>
    );
  }

  function image() {
    return <img className="w-full h-auto" src={props.coverImageUrl} alt={props.title} />;
  }
};

CenterImageSection.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  descriptionRichText: PropTypes.object,
  coverImageUrl: PropTypes.string,
  backgroundColor: PropTypes.string,
};
CenterImageSection.defaultProps = {
  descriptionRichText: {},
  backgroundColor: theme.colorsTw.bg_bg1,
};

export default CenterImageSection;
